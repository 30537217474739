import React from "react";
import BUILDING1 from "./BUILDING1.jpg";
import BUILDING2 from "./BUILDING2.jpg";
import BUILDING3 from "./BUILDING3.jpg";

export default function Building() {
  return (
    <section className="hiking section team">
      <div className="hiking__container bd-grid">
        <div
          data-aos="fade-up"
          className="hiking__bloc hiking__text"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h2 className="section-title title-building">
            PROGRAMME TEAM BUILDING
          </h2>
          <h3>
            {/* Offrez à vos équipes un moment d'évasion à l'occasion d'une balade
          en moto électrique pour votre prochain Team Building. */}
            Vous souhaitez organiser une activité Team Building, journée de
            séminaire pour vos collaborateurs dans la baie de Morlaix ? Melawach
            vous permet de prévoir une journée pour vos équipes avec une
            randonnée adapté à vos envies, au niveau du groupe, à la durée de
            votre choix.
          </h3>
          <div>
            <p className="bold">Déroulement de votre Team Building :</p>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.25rem",
                marginLeft: "1rem",
              }}
            >
              <p>
                Vous serez accueillis par votre guide RONAN pour faire
                connaissance et régler les formalités administratives. Il vous
                sera ensuite présenté les motos électriques que vous utiliserez
                lors de votre randonnée.
              </p>
              <p>
                Vous aurez droit à une démonstration de l'utilisation de la moto
                électrique et vous aurez l'occasion de poser toutes vos
                questions.
              </p>
              <p>
                Viendra ensuite une phase de prise en main sur un terrain dégagé
                et sécurisé pour vous familiariser avec la machine. Elle est
                très simple d'utilisation (uniquement 1 accélérateur et 2
                freins).
              </p>
              <p>
                Vous partirez pour votre randonnée sur les petites routes, les
                pistes et les chemins de la région à la découverte d'une nature
                sauvage et préservée.
              </p>
              <p>
                Vous apprécierez la facilité de pilotage de la moto et surtout
                le quasi-silence de son moteur ! Balade bucolique ou sportive,
                vous n'avez plus qu'à choisir.
              </p>
            </div>
          </div>
          <p>
            Les groupes peuvent être de 6/12 personnes maximum en simultané.
            Pour les groupes de plus grande taille, il sera possible de faire
            des rotations afin d'accueillir jusqu'à 40 personnes sur 1 journée !
          </p>
          <div>
            <p className="bold">
              Tarifs avec une pause dégustation chez un de nos partenaires :
            </p>
            <p>45 € HT | 1 heure</p>
            <p>70 € HT | 2 heures</p>
          </div>
          <p>
            <span className="bold">Conditions :</span> être titulaire du permis
            B ou BSR (une bonne forme physique est conseillée).
          </p>
          <a href="/#contact" className="button">
            Prendre rendez-vous
          </a>
        </div>
        <div
          data-aos="fade-right"
          className="hiking__bloc hiking__image"
          style={{ flexBasis: "30%" }}
        >
          <div>
            <img src={BUILDING3} alt="entreprise" />
          </div>
          <div>
            <img src={BUILDING1} alt="entreprise" />
          </div>

          <div>
            <img src={BUILDING2} alt="entreprise" />
          </div>
        </div>
      </div>
    </section>
  );
}
