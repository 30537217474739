import "./enduro.scss";
import image from "./enduro3.jpg";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

export default function Enduro() {
  useEffect(() => {
    Aos.init({
      duration: 1000,
      once: true,
      delay: 300,
    });
  }, []);

  return (
    <section className="hiking section enduro" id="enduro">
      <div className="hiking__container bd-grid">
        <div data-aos="fade-up" className="hiking__bloc hiking__text">
          <h2 className="section-title">RANDONNÉE ENDURO / EVOLUTION</h2>
          <h3>
            Quel que soit votre niveau, partez à la découverte des plus beaux
            chemins points de vue du Finistère pour une journée ou un week-end.
          </h3>
          <p>
            Les randonnées sont adaptées aux niveaux des participants, entre
            chemins roulants et passages techniques, bénéficiez des conseils du{" "}
            <a className="enduro__link" href="/#guide">
              guide diplômé
            </a>
            .
          </p>
          <p>
            L'évaluation du niveau ainsi que des exercices de progression auront
            lieu sur un <strong>domaine privé</strong>.
          </p>
          <div>
            <p>
              <strong>Conditions d'accès</strong> :
            </p>
            <ul>
              <li>- Moto homologuée pour la route</li>
              <li>- Permis adapté</li>
              <li>- Assurance à jour</li>
              <li>- Équipement complet</li>
              <li>- Location de moto possible (voir conditions)</li>
            </ul>
          </div>
          <div className="enduro__bold">
            <p>Envie de progresser ?</p>
          </div>

          <p>
            Nous disposons d'un domaine privé adapté à l'enduro avec des
            obstacles naturels et artificiels.
          </p>
          <p>
            Notre éducateur sportif diplômé vous accompagne dans votre
            progression.{" "}
          </p>
          <a href="/#contact" className="button">
            Prendre rendez-vous
          </a>
        </div>
        <div data-aos="fade-left" className="hiking__bloc hiking__image">
          <img src={image} alt="randonnée en moto" />
        </div>
      </div>
    </section>
  );
}
