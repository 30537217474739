import "./card.scss";

export default function Card({title, text, img, href, delay}) {
  return (
    <article data-aos="fade-right" data-aos-delay={delay} className="card">
        <div className="card__container">
            <img src={require(`${img}`)} alt="" />
        </div>
        <div className="card__details">
            <h3>{title}</h3>
            <p>{text}</p>
            <a href={href} className="button">Découvrir</a>
        </div>
    </article>
  )
}
