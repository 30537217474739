import { BrowserRouter as Router, Routes } from "react-router-dom";
import Header from './Header';
import Footer from './Footer';

const Layout = ({ children }) => {
    return (
        <Router> 

            <Header />
                <Routes>
                    { children }
                </Routes>
            <Footer />
            
        </Router>
    );
};

export default Layout;