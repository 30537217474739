import { Route } from "react-router-dom";
import './App.scss';
import Error from "./Error";
import Home from './Home';
import Layout from './Layout';
import LegalNotice from "./LegalNotice";
import Groupes from "./Groupes";

function App() {

  return (

    <Layout>
      <Route path="/" element={<Home />} />
      <Route path="/groupes" element={<Groupes />} />
      <Route path="/mentions" element={<LegalNotice />} />
      <Route path="*" element={<Error />} />
    </Layout>

  );

}

export default App;
