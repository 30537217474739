import './error.scss';

export default function Error() {

  return (
    <section className="error section">
        <h2 className="section-title">404</h2>
        <div className="error__container bd-grid">
            <p>La page que vous recherchez semble introuvable.</p>
            <a href="/" className="button">Retour à l'accueil</a>
        </div>
    </section>
  )
}
