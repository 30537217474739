import "./training.scss";
import image from "./training3.jpg";
import image2 from "./danniversaire.jpg";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

export default function Training() {
  useEffect(() => {
    Aos.init({
      duration: 1000,
      once: true,
      delay: 300,
    });
  }, []);

  return (
    <section className="hiking section training" id="initiation">
      <div className="hiking__container bd-grid">
        <div
          data-aos="fade-right"
          className="hiking__bloc hiking__image"
          style={{ flexBasis: "60%" }}
        >
          <img src={image} alt="initiation enfants moto-cross" />
        </div>
        <div data-aos="fade-up" className="hiking__bloc hiking__text">
          <h2 className="section-title">INITIATION MOTO-CROSS ENFANTS</h2>
          <p style={{ fontStyle: "italic" }}>
            À partir du 1er juin, l'activité est déplacée sur le site du terrain
            de motocross de Quelern à Plourin-lès-Morlaix.
          </p>
          <h3>
            Confiez-nous votre enfant (de 6 à 16 ans) pour découvrir la moto
            tout terrain en toute sécurité grâce à nos éducateurs diplômés et
            notre espace dédié.
          </h3>
          <p></p>
          <p>
            Il apprend avec des exercices pédagogiques à piloter une deux roues
            adaptées à sa taille et à son âge.
          </p>
          <p>
            <strong>Nos motos :</strong> CRF 50, CRF 125 et 110 TTR
          </p>
          <p>
            Les initiations sont proposées les mercredis, samedis ainsi qu'en
            vacances scolaires.
          </p>
          <p>
            Les équipements fournis : casque, gants, protections genoux et
            coudes
          </p>
          <p>
            <strong>Tarif</strong> : 50€ | 1h
          </p>
          <a href="/#contact" className="button">
            Prendre rendez-vous
          </a>
          <img src={image2} alt="initiation enfants moto-cross" />
        </div>
      </div>
    </section>
  );
}
