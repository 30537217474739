import Hiking from "./Hiking";
import HomeView from "./HomeView";
import Services from "./Services";
import Contact from "./Contact";
import Training from "./Training";
import Enduro from "./Enduro";
import Guide from "./Guide";
import Surron from "./Surron";

export default function Home() {
  return (
    <main>
      <HomeView />
      {/* <Services /> */}
      <Hiking />
      <Training />
      <Enduro />
      <Surron />
      <Guide />
      <Contact />
    </main>
  );
}
