import React, { useState, useEffect } from "react";
import "./slideshow.scss"; // Assurez-vous d'importer le CSS approprié
import image1 from "./training3.jpg";
import image1MOBILE from "./training3MOBILE.jpg";
import image2MOBILE from "./randoMOBILE.jpg";
import image3MOBILE from "./enduro3MOBILE.jpg";
import image2 from "./rando.jpg";
import image3 from "./enduro3.jpg";
import logo from "./logo.png";

function Slideshow() {
  const slides = [
    {
      image: image1,
      imageMobile: image1MOBILE,
      header: "Initiation moto-cross enfants",
      description:
        "Confiez-nous votre enfant pour découvrir la moto tout terrain en toute sécurité...",
      href: "/#initiation",
    },
    {
      image: image2,
      imageMobile: image2MOBILE,
      header: "Randonnée e-bike",
      description:
        "Suivez-nous au guidon de nos cyclos 100% électriques en baie de Morlaix...",
      href: "/#randonnee",
    },
    {
      image: image3,
      imageMobile: image3MOBILE,
      header: "Enduro",
      description:
        "Quel que soit votre niveau, partez à la découverte des plus beaux chemins...",
      href: "/#enduro",
    },
  ];
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const handleControlClick = (index) => {
    setCurrentSlide(index);
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 8000);
  };

  const [intervalRef] = useState({ current: null });

  return (
    <div className="content-width">
      <div className="slideshow">
        <div className="slideshow-items">
          {slides.map((slide, index) => (
            <div
              key={index}
              className={`item ${index === currentSlide ? "active" : ""}`}
            >
              <div className="item-image-container">
                <img
                  srcSet={`${slide.image} 1200w, ${slide.imageMobile} 500w`}
                  className="item-image"
                  src={slide.image}
                  alt={`Image moto-cross e-bike`}
                />
              </div>
              <div className="item-header">
                <h2>
                  {slide.header.split("").map((char, charIndex) => (
                    <span
                      key={charIndex}
                      className={`vertical-part ${
                        index === currentSlide ? "active" : ""
                      }`}
                    >
                      <b>{char === " " ? "\u00A0" : char}</b>
                    </span>
                  ))}
                </h2>
              </div>
              <div
                className={`slideshow-logo ${
                  index === currentSlide ? "active" : ""
                }`}
              >
                <img src={logo} className="img" />
              </div>
              <div className="container-description">
                <div className="item-description">
                  <h3>
                    {slide.description
                      .split("&nbsp;")
                      .map((word, wordIndex) => (
                        <span
                          key={wordIndex}
                          className={`vertical-part ${
                            index === currentSlide ? "active" : ""
                          }`}
                        >
                          <b>{word === " " ? "\u00A0" : word}</b>
                        </span>
                      ))}
                  </h3>
                  <div
                    className={`slide-button-container ${
                      index === currentSlide ? "active" : ""
                    }`}
                  >
                    <a href={slide.href} className="button">
                      <b>Découvrir</b>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="controls">
          <ul>
            {slides.map((slide, index) => (
              <li
                key={index}
                className={`control ${index === currentSlide ? "active" : ""}`}
                data-index={index}
                onClick={() => handleControlClick(index)}
              ></li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
export default Slideshow;
