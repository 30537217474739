import { useEffect } from "react";
import "./home.scss";
import Aos from "aos";
import "aos/dist/aos.css";
import Slideshow from "../../Slideshow";

export default function HomeView() {
  useEffect(() => {
    Aos.init({
      duration: 1000,
      once: true,
    });
  }, []);

  return (
    <section className="home" id="accueil">
      <div className="home__container">
        {/* <div data-aos="fade-up" data-aos-delay="700" className="home__data">
          <h1 className="home__title">
            suivez-nous au guidon de nos cyclos <br />
            100% électrique en baie de Morlaix
          </h1>
          <p className="home__description">
            Randonnées E-bike, initiation moto-cross enfants, enduro
          </p>
          <div
            style={{
              display: "flex",
              gap: "1rem",
              justifyContent: "center",
              marginTop: 20,
            }}
          >
            <a href="/#services" className="home__button button">
              Activités
            </a>
            <a href="/groupes" className="home__button button">
              Groupes
            </a>
          </div>
        </div> */}
        <Slideshow />
      </div>
    </section>
  );
}
