import "./guide.scss";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import image from "./guide.jpeg";

export default function Guide() {
  useEffect(() => {
    Aos.init({
      duration: 1000,
      once: true,
      delay: 300,
    });
  }, []);

  return (
    <section className="section guide" id="guide">
      <div className="hiking__container guide__container bd-grid">
        <div data-aos="fade-right" className="hiking__bloc guide__image">
          <span>Ronan</span>
          <img src={image} alt="guide" />
        </div>
        <div data-aos="fade-up" className="hiking__bloc hiking__text">
          <h2 className="section-title">VOTRE GUIDE</h2>
          <p>
            "Grâce à mon expérience de pilote dans les différents championnats
            d'enduro et de moto-cross, j'ai toujours eu le désir de faire
            partager ma passion.
          </p>
          <p>
            Je propose aujourd'hui différentes activités, randonnées, cours, qui
            vous permettront de vous initier peu importe votre niveau de 7 à 77
            ans."
          </p>
          <ul className="guide__contact">
            <li>
              <i className="bx bx-envelope"></i> melawachrando@gmail.com
            </li>
            <li>
              <i className="bx bx-phone"></i> 06 63 75 10 68
            </li>
          </ul>
          <div>
            <a
              href="https://www.facebook.com/melawachrando-104135802245096/"
              title="facebook Melawach"
              target="_blank"
              className="footer__social guide__link guide__facebook"
            >
              <i className="bx bxl-facebook-circle"></i>
            </a>
            <a
              href="https://www.instagram.com/melawachrando/"
              title="instagram Melawach"
              target="_blank"
              className="footer__social guide__link guide__instagram"
            >
              <i className="bx bxl-instagram-alt"></i>
            </a>{" "}
            <a
              href="https://www.youtube.com/channel/UC6uQQVJKYMDZ_QkKYY964Eg/"
              title="youtube Melawach"
              target="_blank"
              className="footer__social guide__link guide__youtube"
            >
              <i className="bx bxl-youtube"></i>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
