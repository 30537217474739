import Aos from "aos";
import "aos/dist/aos.css";
import "./groupes.scss";
import { useEffect } from "react";
import Anniversaire from "./Anniversaire";
import EVJF from "./EVJF";
import Building from "./Building";

export default function Groupes() {
  useEffect(() => {
    Aos.init({
      duration: 1000,
      once: true,
      delay: 300,
    });
  }, []);
  return (
    <main>
      <h1 className="section">GROUPES</h1>
      <Building />
      <EVJF />
      <Anniversaire />
    </main>
  );
}
