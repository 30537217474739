import React from "react";
import EVG1 from "./EVG1.jpeg";
import EVG2 from "./evg2.jpg";
import EVG4 from "./evg4.jpg";
import EVG5 from "./evg5.jpg";

export default function EVJF() {
  return (
    <section className="hiking section training evg">
      <div className="hiking__container bd-grid">
        <div
          data-aos="fade-right"
          className="hiking__bloc hiking__image"
          style={{ flexBasis: "35%" }}
        >
          <div>
            <img src={EVG2} alt="EVG" />
          </div>

          <div>
            <img src={EVG4} alt="EVG" />
          </div>
          <div>
            <img src={EVG5} alt="EVG" />
          </div>
        </div>
        <div
          data-aos="fade-up"
          className="hiking__bloc hiking__text"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h2 className="section-title title-evg">
            PROGRAMME EVJF/EVG <br /> DE 2 à 12 PERSONNES
          </h2>
          <h3>
            Profitez d'une balade dans la baie de Morlaix pour découvrir les
            sensations de pilotage d'une moto électrique. Le paysage et le
            patrimoine de la région sont incroyables !
          </h3>
          <div>
            <p className="bold">Déroulement de votre balade :</p>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.25rem",
                marginLeft: "1rem",
              }}
            >
              <p>
                Vous serez accueillis par votre guide Ronan pour faire
                connaissance et régler les formalités administratives. Il vous
                sera ensuite présenté les motos électriques que vous utiliserez
                lors de votre randonnée.
              </p>
              <p>
                Vous aurez droit à une démonstration de l'utilisation de la moto
                électrique et vous aurez l'occasion de poser toutes vos
                questions.
              </p>
              <p>
                Viendra ensuite une phase de prise en main sur un terrain dégagé
                et sécurisé pour vous familiariser avec la machine. Elle est
                très simple d'utilisation (uniquement 1 accélérateur et 2
                freins).
              </p>
              <p>
                Vous partirez pour votre randonnée sur les petites routes, les
                pistes et les chemins de la région à la découverte d'une nature
                sauvage et préservée.
              </p>
              <p>
                Vous apprécierez la facilité de pilotage de la moto et surtout
                le quasi-silence de son moteur ! Balade bucolique ou sportive,
                vous n'avez plus qu'à choisir.
              </p>
            </div>
          </div>
          <p>
            Les parcours vous seront proposés en fonction de votre niveau et de
            vos envies. Les machines souples et confortables permettent
            d'évoluer avec élégance et efficacité sur tous les terrains qui se
            présenteront à vous !
          </p>
          <p>
            C'est l'activité idéale pour fêter entre potes un EVG/EVJF ! Vous
            pourrez vous arrêter faire des pauses dans de superbes endroits et
            pourrez lancer des défis au futur marié ou à la future mariée.
          </p>
          <div>
            <p className="bold">
              Tarifs avec une pause dégustation chez un de nos partenaires :
            </p>
            <p>55 € | 1 heure</p>
            <p>85 € | 2 heures</p>
          </div>
          <p>
            <span className="bold">Conditions :</span> être titulaire du permis
            B ou BSR (une bonne forme physique est conseillée).
          </p>
          <a href="/#contact" className="button">
            Prendre rendez-vous
          </a>
        </div>
      </div>
    </section>
  );
}
