import "./nav.scss";
import IconButton from "../../IconButton";
import { useEffect, useState } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import logo from "./logo.png";

export default function Header() {
  const [showMenu, setShowMenu] = useState(false);

  const routes = [
    { route: "/#accueil", name: "Accueil" },
    { route: "/#randonnee", name: "Randonnées" },
    { route: "/#initiation", name: "Initiation" },
    { route: "/#enduro", name: "Enduro" },
    { route: "/groupes", name: "Groupes" },
  ];

  useEffect(() => {
    Aos.init({
      duration: 100,
      once: true,
    });
  }, []);

  return (
    <header data-aos="fade-down" className={`l-header`} id="header">
      <nav className="nav bd-grid">
        {/* <img src={logo} style={{ height: "80%" }} /> */}
        <a href="/" className="nav__logo">
          Melawach
        </a>

        <IconButton
          arialabel="ouvrir menu"
          onClick={() => setShowMenu(!showMenu)}
        >
          <i className="bx bx-menu"></i>
        </IconButton>

        <div className={`nav__menu ${showMenu ? "show" : ""}`} id="nav-menu">
          <ul className="nav__list">
            {routes.map((element) => (
              <li className="nav__item" key={element.route}>
                <a
                  href={element.route}
                  className="nav__link"
                  onClick={() => setShowMenu(!showMenu)}
                >
                  {element.name}
                </a>
              </li>
            ))}
            <li className="nav__item">
              {/* <a 
                  href='https://www.airbnb.fr/rooms/575343724100110706?guests=1&adults=1&s=67&unique_share_id=c1b290c3-cbb6-44ba-86be-d51ce1aa9cdf' 
                  className="nav__link button"
                  target="_blank"
                  onClick={() => setShowMenu(!showMenu)}
                >
                  Hébergement Airbnb
                </a> */}
              <a
                href="/#contact"
                className="nav__link button"
                onClick={() => setShowMenu(!showMenu)}
              >
                Contact
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
}
