import "./hiking.scss";
import image from "./hiking.jpg";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

export default function Hiking() {
  useEffect(() => {
    Aos.init({
      duration: 1000,
      once: true,
      delay: 300,
    });
  }, []);
  return (
    <section className="hiking section" id="randonnee">
      <div className="hiking__container bd-grid">
        <div data-aos="fade-up" className="hiking__bloc hiking__text">
          <h2 className="section-title">RANDONNÉE E-BIKE</h2>
          <h3>Une activité à partager en famille ou entre amis !</h3>
          <p>
            Suivez-nous au guidon de nos cycles 100% électrique tout-terrain{" "}
            <strong>en baie de Morlaix</strong> accessible aux débutants comme
            aux confirmés.
          </p>
          <p>
            Nous proposons des parcours variés et adaptés qui vous
            émerveilleront tout au long de la randonnée.
          </p>
          <p>
            Nos e-bike sont accessibles à partir de 14 ans avec le{" "}
            <strong>BSR</strong> ou le <strong>permis B</strong>.
          </p>
          <div className="hiking__formule">
            <span>
              <div className="bold">Formule découverte</div>
              <div className="min">30m / environ 10kms</div>
              <div className="max">30€</div>
            </span>
            <span>
              <div className="bold">Formule expérience</div>
              <div className="min">1H / environ 20kms</div>
              <div className="max">55€</div>
            </span>
            <span>
              <div className="bold">Formule exploration</div>
              <div className="min">2H / environ 30kms</div>
              <div className="max">85€</div>
            </span>
          </div>
          <a href="/#contact" className="button">
            Prendre rendez-vous
          </a>
        </div>
        <div data-aos="fade-left" className="hiking__bloc hiking__image">
          <img src={image} alt="randonnée en moto" />
        </div>
      </div>
    </section>
  );
}
