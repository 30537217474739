import "./surron.scss";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

export default function Surron() {
  useEffect(() => {
    Aos.init({
      duration: 1000,
      once: true,
      delay: 300,
    });
  }, []);

  return (
    <section className="section surron">
      <div className="bd-grid surron__container">
        <div className="surron__bloc surron__text">
          <h2
            data-aos="fade-down"
            style={{ textTransform: "uppercase" }}
            className="section-title"
          >
            Présentation
          </h2>
        </div>
        <div data-aos="fade-up" className="surron__bloc surron__video">
          <iframe
            loading="lazy"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/CrnKBrQsK_M"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <div style={{ maxWidth: "500px" }}>
          <div data-aos="fade-up" className="surron__bloc surron__short">
            <iframe
              loading="lazy"
              src="https://www.youtube.com/embed/a6zzone-eJY"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
}
