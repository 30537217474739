import React from "react";
import anniversaire from "./danniversaire.jpg";
import anniversaire2 from "./danniversaire2.jpg";
import anniversaire3 from "./danniversaire3.jpg";

export default function Anniversaire() {
  return (
    <section className="hiking section anniversaire">
      <div className="hiking__container bd-grid">
        <div
          data-aos="fade-up"
          className="hiking__bloc hiking__text"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h2 className="section-title title-anniversaire">
            PROGRAMME PACK ANNIVERSAIRE <br />
            DE 4 A 10 ENFANTS
          </h2>
          <h3>
            Faites sensation auprès de votre enfant et ses copains en lui
            organisant une fête d'anniversaire inoubliable au cours de laquelle
            les enfants apprendront à conduire une moto-cross. Supervisée par
            RONAN, pilote breveté et expérimenté, l'activité plaira énormément
            aux enfants, filles comme garçons !
          </h3>
          <div>
            <p className="bold">Déroulement de l'anniversaire Moto-cross :</p>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.25rem",
                marginLeft: "1rem",
              }}
            >
              <p>
                A leurs arrivée, les enfants seront accueillis par RONAN,
                équipés et devront assister à un briefing sur les règles de
                sécurité à respecter avant de démarrer l'activité.
              </p>
              <p>
                30 minutes ou 1h de moto-cross : les enfants profiteront d'une
                initiation à la conduite de la <strong>moto-cross</strong> sur
                un terrain spécialement adapté avec une moto adaptée à leur âge.
                L'activité se fera sous la supervision de RONAN, pilote
                professionnel.
              </p>
            </div>
          </div>
          <p>
            ET S'IL VOUS RESTE DE L'ENERGIE UNE CHASSE AUX TRESORS VOUS ATTEND
            D'UNE DUREE DE 1H30 ENVIRON (accompagnement d'un adulte
            obligatoire).
          </p>
          <p>
            Goûter d'anniversaire dans un espace dédié (table de pique-nique en
            extérieur ou bungalow intérieur si mauvais temps) avec boissons et
            bonbons. Le gâteau reste à la charge des parents.
          </p>
          <p>
            <span className="bold">Matériel et encadrement :</span> les
            moto-cross sont spécialement adaptées pour les enfants à partir de 6
            ans, les enfants sont équipés d'un casque avec charlotte. Le centre
            de loisirs est géré par RONAN, passionné de moto depuis son plus
            jeune âge. Titulaire du CQP INITIATEUR, il saura vous faire
            découvrir la moto-cross comme personne !
          </p>
          <p>
            L'anniversaire Moto-cross fera sensation auprès de votre enfant et
            de ses copains !
          </p>
          <div>
            <p className="bold">
              Tarifs avec Initiation + Chasse aux trésors + Accès table de
              pique-nique :
            </p>
            <p>30 € | 30 min de moto</p>
            <p>50 € | 1h de moto</p>
          </div>
          <p>
            <span className="bold">Conditions :</span> savoir faire du vélo.
          </p>
          <a href="/#contact" className="button">
            Prendre rendez-vous
          </a>
        </div>
        <div
          data-aos="fade-right"
          className="hiking__bloc hiking__image"
          style={{ flexBasis: "35%" }}
        >
          <div>
            <img src={anniversaire} alt="anniversaire" />
          </div>

          <div>
            <img src={anniversaire3} alt="anniversaire3" />
          </div>
          <div>
            <img src={anniversaire2} alt="anniversaire2" />
          </div>
        </div>
      </div>
    </section>
  );
}
