import "./footer.scss";
import image from "./logo.png";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer__container bd-grid">
        <div className="footer__box">
          <h3 className="footer__title">Melawach</h3>
          <p className="footer__description">
            <img src={image} alt="logo" />
          </p>
          <ul>
            <li>
              <a href="/mentions" className="footer__link">
                Mentions Légales
              </a>
            </li>
          </ul>
        </div>
        <div className="footer__box">
          <h3 className="footer__title">EXPLORER</h3>
          <ul>
            <li>
              <a href="/#accueil" className="footer__link">
                Accueil
              </a>
            </li>
            <li>
              <a href="/#randonnee" className="footer__link">
                Randonnées
              </a>
            </li>
            <li>
              <a href="/#initiation" className="footer__link">
                Initiation
              </a>
            </li>
            <li>
              <a href="/#enduro" className="footer__link">
                Enduro
              </a>
            </li>
          </ul>
        </div>
        <div className="footer__box">
          <h3 className="footer__title">CONTACT</h3>
          <ul>
            <li>
              <button className="footer__link">
                <i className="bx bx-envelope"></i> melawachrando@gmail.com
              </button>
            </li>
            <li>
              <button className="footer__link">
                <i className="bx bx-phone"></i> 06 63 75 10 68
              </button>
            </li>
            <li>
              <button className="footer__link">
                Rue de Plouigneau, 29610 Garlan
              </button>
            </li>
          </ul>
          <a
            href="https://www.facebook.com/melawachrando-104135802245096/"
            title="facebook Melawach"
            target="_blank"
            className="footer__social"
          >
            <i className="bx bxl-facebook"></i>
          </a>
          <a
            href="https://www.instagram.com/melawachrando/"
            title="Instagram Melawach"
            target="_blank"
            className="footer__social"
          >
            <i className="bx bxl-instagram"></i>
          </a>
          <a
            href="https://www.youtube.com/channel/UC6uQQVJKYMDZ_QkKYY964Eg/"
            title="Youtube Melawach"
            target="_blank"
            className="footer__social"
          >
            <i className="bx bxl-youtube"></i>
          </a>
        </div>
      </div>
      <div className="footer__link copyright">
        © Copyright Melawach {new Date().getFullYear()} - Site développé par
        <a href="https://www.claudiegueguen.com/" target="_blank">
          {" "}
          Claudie
        </a>
      </div>
    </footer>
  );
}
