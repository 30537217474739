import "./contact.scss";
import Aos from "aos";
import "aos/dist/aos.css";
import { useState, useEffect } from "react";
import emailjs from "emailjs-com";

export default function Contact() {
  const [message, setMessage] = useState("");
  const [prenom, setPrenom] = useState("");
  const [nom, setNom] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [comment, setComment] = useState("");
  const [nombre, setNombre] = useState(0);
  const [age, setAge] = useState(0);
  const [taille, setTaille] = useState(0);
  const [permis, setPermis] = useState("");
  const [niveau, setNiveau] = useState("");
  const [selectedService, setSelectedService] = useState(""); // Ajout de l'état

  function sendEmail(event) {
    event.preventDefault();

    emailjs
      .sendForm(
        "service_2m42s7t",
        "template_4kqdl18",
        event.target,
        "rEZStEygcfZfRh6UU"
      )
      .then((response) => {
        setMessage("Message envoyé, merci!");
        setPrenom("");
        setNom("");
        setEmail("");
        setTel("");
        setComment("");
        setNombre("");
        setTaille(0);
        setPermis("");
        setNiveau("");
        setAge(0);
        setSelectedService("");
      })
      .catch((error) => {
        setMessage(
          "Envoi du message échoué. Merci de réessayer ultérieurement ou nous contacter par téléphone au 06 63 75 10 68 ou par mail : melawachrando@gmail.com"
        );
      });
  }

  useEffect(() => {
    Aos.init({
      duration: 1000,
      once: true,
      delay: 300,
    });
  }, []);

  return (
    <section className="contact section" id="contact">
      <h2 data-aos="fade-down" className="section-title">
        CONTACT
      </h2>

      <form className="contact__container bd-grid" onSubmit={sendEmail}>
        <p style={{ marginBottom: 10 }}>
          Réservez dès maintenant ! Notre réouverture est prévue pour le 30 mars
          2024.
        </p>
        <div data-aos="fade-up" className="form__group">
          <label htmlFor="service-select">
            Choisir un service <em>&#x2a;</em>
          </label>

          <select
            name="services"
            id="service-select"
            value={selectedService}
            onChange={(event) => setSelectedService(event.target.value)}
          >
            <option value="">Sélectionner un service</option>
            <option value="randonnée ebike">Randonnée e-bike</option>
            <option value="initiation moto-cross enfants">
              Initiation moto-cross enfants
            </option>
            <option value="randonnée enduro">Randonnée enduro</option>
            <option value="programme pack anniversaire">
              Programme pack anniversaire
            </option>
            <option value="programme EVJF/EVG">Programme EVJF/EVG</option>
            <option value="programme Team Building">
              Programme Team Building
            </option>
          </select>
        </div>

        <div className="form__bloc">
          <div data-aos="fade-up" data-aos-delay="200" className="form__group">
            <label htmlFor="prenom">
              Prénom <em>&#x2a;</em>
            </label>
            <input
              id="prenom"
              name="prenom"
              value={prenom}
              onChange={(event) => setPrenom(event.target.value)}
              required
              type="text"
            />
          </div>
          <div data-aos="fade-up" data-aos-delay="200" className="form__group">
            <label htmlFor="nom">
              Nom <em>&#x2a;</em>
            </label>
            <input
              id="nom"
              name="nom"
              value={nom}
              onChange={(event) => setNom(event.target.value)}
              required
              type="text"
            />
          </div>
        </div>
        <div className="form__bloc">
          <div data-aos="fade-up" data-aos-delay="400" className="form__group">
            <label htmlFor="email">
              Email <em>&#x2a;</em>
            </label>
            <input
              id="email"
              name="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              required
              type="email"
            />
          </div>
          <div data-aos="fade-up" data-aos-delay="400" className="form__group">
            <label htmlFor="tel">
              Téléphone <em>&#x2a;</em>
            </label>
            <input
              id="tel"
              name="tel"
              value={tel}
              onChange={(event) => setTel(event.target.value)}
              required
              type="text"
            />
          </div>
        </div>
        {(selectedService === "programme pack anniversaire" ||
          selectedService === "programme EVJF/EVG" ||
          selectedService === "programme Team Building") && (
          <div className="form__bloc">
            <div className="form__group">
              <label htmlFor="nombre">Nombre de personnes</label>
              <input
                id="nombre"
                name="nombre"
                value={nombre}
                onChange={(event) => setNombre(event.target.value)}
                type="number"
              />
            </div>
            <div className="form__group">
              <label>Permis</label>
              <div style={{ display: "flex", flexDirection: "row", gap: 5 }}>
                <div>
                  <input
                    id="permisB"
                    name="permis"
                    type="radio"
                    value="B"
                    checked={permis === "B"}
                    onChange={() => setPermis("B")}
                  />
                  <label htmlFor="permisB">B</label>
                </div>
                <div>
                  <input
                    id="permisBSR"
                    name="permis"
                    type="radio"
                    value="BSR"
                    checked={permis === "BSR"}
                    onChange={() => setPermis("BSR")}
                    required
                  />
                  <label htmlFor="permisBSR">BSR</label>
                </div>
              </div>
            </div>
          </div>
        )}
        {selectedService === "initiation moto-cross enfants" && (
          <div className="form__bloc">
            <div className="form__group">
              <label htmlFor="age">Age</label>
              <input
                id="age"
                name="age"
                value={age}
                onChange={(event) => setAge(event.target.value)}
                type="number"
              />
            </div>
            <div className="form__group">
              <label htmlFor="taille">Taille</label>
              <input
                id="taille"
                name="taille"
                value={taille}
                onChange={(event) => setTaille(event.target.value)}
                type="number"
              />
            </div>
            <div className="form__group">
              <label htmlFor="niveau">Niveau de pratique</label>
              <input
                id="niveau"
                name="niveau"
                value={niveau}
                onChange={(event) => setNiveau(event.target.value)}
                type="text"
              />
            </div>
          </div>
        )}
        <div data-aos="fade-up" data-aos-delay="800" className="form__group">
          <label htmlFor="message">
            Message <em>&#x2a;</em>
          </label>
          <textarea
            id="message"
            name="message"
            value={comment}
            onChange={(event) => setComment(event.target.value)}
            required
            rows="4"
          ></textarea>
        </div>
        <div className="contact__button">
          <button className="button">Envoyer</button>
        </div>
        {message}
      </form>
    </section>
  );
}
